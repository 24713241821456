import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL

const sortOptions = [
  { label: 'Newest', value: 'newest' },
  { label: 'Oldest', value: 'oldest' },
  { label: 'Prompt (A-Z)', value: 'alphabetical' },
];

// Create a cache outside of the component to persist across re-renders
const imageCache = new Map();

const AuthenticatedImage = ({ src, alt, className, width, height }) => {
  const [imageSrc, setImageSrc] = useState('');
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    const fetchImage = async () => {
      // Check if the image is already in the cache
      if (imageCache.has(src)) {
        setImageSrc(imageCache.get(src));
        return;
      }

      try {
        const response = await fetch(src, {
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);

        // Only update state if the component is still mounted
        if (isMounted.current) {
          setImageSrc(objectURL);
          imageCache.set(src, objectURL);
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };

    fetchImage();

    // Cleanup function
    return () => {
      isMounted.current = false;
    };
  }, [src]);

  if (!imageSrc) {
    return <div>Loading...</div>;
  }

  return (
    <img
      src={imageSrc}
      alt={alt}
      className={className}
      width={width}
      height={height}
    />
  );
};

export default function History({ title, includePublic, includePrivate }) {
  const [history, setHistory] = useState([]);
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('newest');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/video/`,
          {
            params: {
              public: includePublic,
              private: includePrivate,
            },
            withCredentials: true
          }
        );

        setHistory(response.data);

        // Fetch user data for each video
        const userIds = [...new Set(response.data.map(item => item.by))];
        const userPromises = userIds.map(id =>
          axios.get(`${API_URL}/auth/users/${id}`, { withCredentials: true })
        );

        const userResponses = await Promise.all(userPromises);
        const userMap = {};
        userResponses.forEach(response => {
          userMap[response.data.id] = response.data.username;
        });

        setUsers(userMap);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching video history or user data:', err);
        setError('Failed to load video history. Please try again later.');
        setLoading(false);
      }
    };

    fetchHistory();
  }, [includePublic, includePrivate]);

  const filteredAndSortedHistory = [...history]
    .filter(item =>
      item.prompt.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (users[item.by] && users[item.by].toLowerCase().includes(searchTerm.toLowerCase()))
    )
    .sort((a, b) => {
      switch (sortBy) {
        case 'newest':
          return new Date(b.created_at) - new Date(a.created_at);
        case 'oldest':
          return new Date(a.created_at) - new Date(b.created_at);
        case 'alphabetical':
          return a.prompt.localeCompare(b.prompt);
        default:
          return 0;
      }
    });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="max-w-6xl mx-auto px-2 sm:px-4">
      <h1 className="text-2xl sm:text-3xl font-bold mb-4 mt-0 md:mt-6">{title}</h1>
      <div className="mb-4 flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4">
        <div className="flex-grow">
          <input
            type="text"
            placeholder="Search prompts, status, or creators..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full border rounded-md px-2 py-1 sm:px-3 sm:py-2 text-sm"
          />
        </div>
        <div className="flex items-center">
          <label htmlFor="sort" className="mr-2 font-medium text-sm">Sort by:</label>
          <select
            id="sort"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="border rounded-md px-1 py-1 sm:px-2 sm:py-2 text-sm"
          >
            {sortOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      {filteredAndSortedHistory.length === 0 ? (
        <p className="text-sm">No videos match your search.</p>
      ) : (
        <ul className="space-y-4 sm:space-y-6">
          {filteredAndSortedHistory.map((item) => (
            <li key={item.id} className="flex flex-col sm:flex-row border rounded-lg shadow overflow-hidden">
              <Link to={`/video/${item.id}`} className="flex flex-col sm:flex-row w-full hover:bg-gray-50 transition-colors">
                <div className="w-full sm:w-48 h-32 sm:h-auto flex-shrink-0 bg-gray-200">
                  {item.thumbnail_path ? (
                    <AuthenticatedImage
                      src={`${API_URL}/video/${item.id}/thumbnail`}
                      alt={`Thumbnail for ${item.prompt}`}
                      className="w-full h-full object-cover"
                      width={192}
                      height={128}
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <span className="text-gray-500 text-sm">No thumbnail</span>
                    </div>
                  )}
                </div>
                <div className="p-3 sm:p-4 flex flex-col justify-between flex-grow">
                  <div>
                    <h2 className="text-lg sm:text-xl font-semibold mb-2 line-clamp-2">{item.prompt}</h2>
                    <p className="text-xs sm:text-sm text-gray-600 mb-1">
                      Generated on: {new Date(item.created_at).toLocaleString()}
                    </p>
                  </div>
                  <div className="text-xs sm:text-sm text-gray-500 flex justify-between items-center">
                    <span>Created by: {users[item.by] || 'Unknown'}</span>
                    <span className={`px-2 py-1 rounded ${item.public ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'}`}>
                      {item.public ? 'Published' : 'Private'}
                    </span>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
