import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { logout } from '../utils/auth';
import ChangePassword from '../components/ChangePassword';
import PricingPlan from '../components/PricingPlan';
import CurrentPlan from '../components/CurrentPlan';

const API_URL = process.env.REACT_APP_API_URL;

const Account = () => {
  const navigate = useNavigate();
  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-6">
      <h2 className="text-xl font-semibold mb-4">Account</h2>
      <ChangePassword />
      <button
        onClick={handleLogout}
        className="mt-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Logout
      </button>
    </div>
  );
};


const Billing = ({ currentPlan, isLoading, fetchCurrentPlan }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isReactivating, setIsReactivating] = useState(false);
  const [isLoadingPlanDetails, setIsLoadingPlanDetails] = useState(true);
  const [availablePlans, setAvailablePlans] = useState([]);

  useEffect(() => {
    const fetchPlanDetails = async () => {
      setIsLoadingPlanDetails(true);
      try {
        const response = await axios.get(`${API_URL}/billing/plans`, { withCredentials: true });
        setAvailablePlans(response.data);
      } catch (error) {
        console.error('Error fetching plan details:', error);
      } finally {
        setIsLoadingPlanDetails(false);
      }
    };

    fetchPlanDetails();
  }, []);

  const handleCancelSubscription = async () => {
    setIsCancelling(true);
    try {
      await axios.post(`${API_URL}/billing/cancel`, {}, { withCredentials: true });
      // Refresh the current plan after cancellation
      await fetchCurrentPlan();
    } catch (error) {
      console.error('Error cancelling subscription:', error);
    } finally {
      setIsCancelling(false);
      setShowConfirmation(false);
    }
  };

  const handleReactivateSubscription = async () => {
    setIsReactivating(true);
    try {
      await axios.post(`${API_URL}/billing/reactivate`, {}, { withCredentials: true });
      // Refresh the current plan after reactivation
      await fetchCurrentPlan();
    } catch (error) {
      console.error('Error reactivating subscription:', error);
    } finally {
      setIsReactivating(false);
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-6">
      <h2 className="text-2xl font-semibold mb-6">Current Plan</h2>
      {isLoading || isLoadingPlanDetails ? (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      ) : currentPlan ? (
        <CurrentPlan
          currentPlan={currentPlan}
          onCancelClick={() => setShowConfirmation(true)}
          onReactivateClick={handleReactivateSubscription}
          isReactivating={isReactivating}
        />
      ) : (
        <div>
          <CurrentPlan
            currentPlan={{
              name: "Free Plan",
              price: 0,
              features: {
                " - API Requests": "Limited to 50 per month",
                " - Platform Access": "Basic access",
                " - Response Time": "Standard"
              },
              cancelAtPeriodEnd: false
            }}
            onCancelClick={null}
            onReactivateClick={null}
            isReactivating={false}
          />
          <p className="text-sm text-gray-600 italic mt-4 mb-6">Upgrade to unlock premium features and enhance your experience!</p>

          <h2 className="text-2xl font-semibold mb-6">Available Plans</h2>
          <div className="mx-auto grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:max-w-5xl">
            {availablePlans.map((plan, index) => (
              <PricingPlan
                key={index}
                title={plan.name}
                price={plan.price}
                features={plan.features}
                planId={plan.id}
                buttonText="Upgrade Now"
              />
            ))}
          </div>
        </div>
      )}

      {showConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div className="bg-white p-5 rounded-lg shadow-xl relative">
            <h3 className="text-lg font-semibold mb-2">Confirm Cancellation</h3>
            <p>Are you sure you want to cancel your subscription? You'll still have access until the end of your current billing period.</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => setShowConfirmation(false)}
                className="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                No, keep my subscription
              </button>
              <button
                onClick={handleCancelSubscription}
                disabled={isCancelling}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                {isCancelling ? 'Cancelling...' : 'Yes, cancel my subscription'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default function Settings() {
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);

  const fetchCurrentPlan = useCallback(async () => {
    if (currentPlan) return; // Skip if data is already loaded
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}/billing/current-plan`, { withCredentials: true });
      setCurrentPlan(response.data);
    } catch (error) {
      console.error('Error fetching current plan:', error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPlan]);

  useEffect(() => {
    fetchCurrentPlan();
  }, [fetchCurrentPlan]);

  const handleUpgrade = async (planId) => {
    setIsUpgrading(true);
    try {
      const response = await axios.get(`${API_URL}/billing/checkout`, {
        params: { planId },
        withCredentials: true
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setIsUpgrading(false);
    }
  };

  return (
    <div className="max-w-6xl mx-auto px-4">
      <h1 className="text-3xl font-bold mb-6">Settings</h1>

      <Billing
        currentPlan={currentPlan}
        isLoading={isLoading}
        handleUpgrade={handleUpgrade}
        isUpgrading={isUpgrading}
        fetchCurrentPlan={fetchCurrentPlan}
      />

      <Account />

    </div>
  );
}
