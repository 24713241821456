import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import Layout from './components/Layout';
import Login from './pages/Login';
import FAQ from './pages/FAQ';
import NotFound from './pages/NotFound';
import ResetPassword from './pages/ResetPassword';
import Register from './pages/Register';
import Create from './pages/Create';
import History from './pages/History';
import Settings from './pages/Settings';
import VideoPage from './pages/VideoPage';
import GoogleCallback from './components/GoogleCallback';
import { setupAxiosInterceptors } from './utils/auth';

const isAuthenticated = () => {
  return localStorage.getItem('isAuthenticated') === 'true';
};

const ProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function App() {
  useEffect(() => {
    setupAxiosInterceptors();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/faq" element={<FAQ />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Create />} />
          <Route path="feed" element={<History title='Feed' includePublic={true} includePrivate={false} />} />
          <Route path="studio" element={<History title='Studio' includePublic={false} includePrivate={true} />} />
          <Route path="settings" element={<Settings />} />
          <Route path="video/:jobId" element={<VideoPage />} />
        </Route>
        <Route path="/auth/google/callback" element={<GoogleCallback />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Analytics />
    </BrowserRouter>
  );
}

export default App;
