import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { AlertCircle, CheckCircle, Clock, RefreshCw, ChevronDown, ChevronUp, Edit, Save } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

const StatusIcon = ({ status }) => {
  if (status === 'done') {
    return <CheckCircle className="text-green-500" />;
  } else if (status === 'failed') {
    return <AlertCircle className="text-red-500" />;
  } else {
    return <Clock className="text-yellow-500" />;
  }
};


const getRetryValue = (step) => {

  console.log(`STEP: ${step}`)
  switch (step) {
    case 'explainer':
      return 'explanation';
    case 'scenewriter':
      return 'write';
    case 'scripter':
      return 'script';
    case 'builder':
      return 'build';
    case 'dubbing':
      return 'dubbing';
    default:
      return '';
  }
};


const RetryButton = ({ onClick, disabled }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`ml-2 px-2 py-1 text-sm rounded ${
      disabled
        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
        : 'bg-blue-500 text-white hover:bg-blue-600'
    }`}
  >
    <RefreshCw className="w-4 h-4 inline mr-1" /> Retry
  </button>
);

const Asset = ({ videoId, sceneId, assetId, asset, onRetry, onUpdate }) => {
  const [isExpanded, setIsExpanded] = useState(asset.status === 'failed');
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(asset.content);

  const handleSave = async () => {
    await onUpdate(videoId, sceneId, assetId, editedContent);
    setIsEditing(false);
  };

  return (
    <li className="mb-2">
      <div className="flex items-center gap-2">
        {asset.status === 'failed' && (
        <RetryButton
          onClick={() => onRetry('build', assetId)}
          disabled={false}
        />
        )}
        <StatusIcon status={asset.status} />
        <span>
          Asset {assetId} - {asset.type.replace('_', ' ')}: {asset.status}
        </span>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="ml-2 text-blue-500 hover:text-blue-700"
        >
          {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
      </div>
      {isExpanded && (
        <div className="mt-2 ml-6 p-2 bg-gray-100 rounded">
          {isEditing ? (
            <>
              <textarea
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
                className="w-full p-2 border rounded mt-2"
                rows={10}
              />
              <button
                onClick={handleSave}
                className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                <Save size={20} className="inline mr-2" /> Save
              </button>
            </>
          ) : (
            <>
              <pre className="text-sm whitespace-pre-wrap overflow-auto max-h-40">
                {asset.content}
              </pre>
              <button
                onClick={() => setIsEditing(true)}
                className="mt-2 text-blue-500 hover:text-blue-700"
              >
                <Edit size={20} className="inline mr-2" /> Edit
              </button>
            </>
          )}
        </div>
      )}
    </li>
  );
};

const Scene = ({ scene, videoId, onRetry, onUpdateAsset, onUpdateScene }) => {
  const [isExpanded, setIsExpanded] = useState(
    Object.values(scene.assets).some(asset => asset.status !== 'done')
  );
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDescription, setEditedDescription] = useState(scene.description);

  const handleSave = async () => {
    await onUpdateScene(videoId, scene.id, editedDescription);
    setIsEditing(false);
  };

  return (
    <div className="mb-4 border-b pb-4">
      <div className="flex items-center justify-between">
        <h4 className="text-lg font-semibold">{scene.name}</h4>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="ml-2 text-blue-500 hover:text-blue-700"
        >
          {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
      </div>
      <div className="flex items-center gap-2 mt-2">
        {scene.status === 'failed' && (
        <RetryButton
        onClick={() => onRetry(getRetryValue(scene.step), scene.id)}
          disabled={false}
        />
        )}
        <StatusIcon status={scene.status} />
        <span>{scene.step}: {scene.status}</span>
      </div>

      {isExpanded && (


        <>

        <div className="mt-4">
            <div className="flex items-center gap-2">
              <h5 className="text-md font-semibold">Description</h5>
              <button
                onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                className="ml-2 text-blue-500 hover:text-blue-700"
              >
                {isDescriptionExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </button>
            </div>
            {isDescriptionExpanded && (
              isEditing ? (
                <div className="mt-2">
                  <textarea
                    value={editedDescription}
                    onChange={(e) => setEditedDescription(e.target.value)}
                    className="w-full p-2 border rounded"
                    rows={10}
                  />
                  <button
                    onClick={handleSave}
                    className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                  >
                    <Save size={20} className="inline mr-2" /> Save
                  </button>
                </div>
              ) : (
                <div className='mt-2 ml-6 p-2 bg-gray-100 rounded'>
                  <pre className="text-sm whitespace-pre-wrap overflow-auto max-h-60">
                    {scene.description}
                  </pre>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="mt-2 text-blue-500 hover:text-blue-700"
                  >
                    <Edit size={20} className="inline mr-2" /> Edit Description
                  </button>
                </div>
              )
            )}
          </div>

          <h5 className="text-md font-semibold mt-4">Assets</h5>
          <ul className="list-none pl-0">
            {Object.entries(scene.assets).map(([assetId, asset]) => (
              <Asset
                key={assetId}
                videoId={videoId}
                sceneId={scene.id}
                assetId={assetId}
                asset={asset}
                onRetry={(step, assetId) => onRetry(step, scene.id, assetId)}
                onUpdate={onUpdateAsset}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

const VideoStatus = ({ jobId }) => {
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchVideoStatus = useCallback(async () => {
    try {

      const response = await axios.get(
        `${API_URL}/video/${jobId}`,
        {
          withCredentials: true
        }
      );

      setVideo(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch video status');
      setLoading(false);
    }
  }, [jobId]);

  useEffect(() => {
    fetchVideoStatus();
    const intervalId = setInterval(fetchVideoStatus, 5000); // Refresh every 5 seconds

    return () => clearInterval(intervalId);
  }, [fetchVideoStatus]);

  const retryStep = useCallback(async (step, sceneId = null, assetId = null) => {
    try {
      let endpoint = `${API_URL}/video/${jobId}`;
      if (sceneId) {
        endpoint += `/scenes/${sceneId}`;
        if (assetId) {
          endpoint += `/assets/${assetId}`;
        }
      }
      endpoint += `/${step}`;

      await axios.post(endpoint, {}, {
        withCredentials: true
      });

      fetchVideoStatus();
    } catch (err) {
      console.error('Failed to retry step:', err);
    }
  }, [jobId, fetchVideoStatus]);

  const updateAsset = useCallback(async (videoId, sceneId, assetId, newContent) => {
    try {
      await axios.put(`${API_URL}/video/${videoId}/scenes/${sceneId}/assets/${assetId}`, null, {
        params: { content: newContent },
        withCredentials: true
      });
      fetchVideoStatus();
    } catch (err) {
      console.error('Failed to update asset:', err);
    }
  }, [fetchVideoStatus]);

  const updateScene = useCallback(async (videoId, sceneId, newDescription) => {
    try {
      await axios.put(`${API_URL}/video/${videoId}/scenes/${sceneId}`, null, {
        params: { description: newDescription },
        withCredentials: true
      });
      fetchVideoStatus();
    } catch (err) {
      console.error('Failed to update scene:', err);
    }
  }, [fetchVideoStatus]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!video) return <div>No video found</div>;

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">{video.prompt}</h2>
      <div className="mb-4">
        <h3 className="text-xl font-semibold mb-1">Video</h3>
        <div className="flex items-center gap-2">
          {video.status === 'failed' && (
          <RetryButton
          onClick={() => retryStep(getRetryValue(video.step))}
            disabled={false}
          />
          )}
          <StatusIcon status={video.status} />
          <span>{video.step}: {video.status}</span>
        </div>
      </div>

      <h3 className="text-xl font-semibold mb-2">Scenes</h3>
      {video.scenes.map(scene => (
        <Scene
          key={scene.id}
          scene={scene}
          videoId={video.id}
          onRetry={retryStep}
          onUpdateAsset={updateAsset}
          onUpdateScene={updateScene}
        />
      ))}
    </div>
  );
};

export default VideoStatus;
