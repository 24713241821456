import React from 'react';
import axios from 'axios';
import { Check } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;


const PricingPlan = ({ title, price, features, planId, buttonText = "Get Started", isEnterprise = false }) => {
  const handleCheckout = async () => {
    try {
      const response = await axios.get(`${API_URL}/billing/checkout`, {
        params: { planId },
        withCredentials: true
      });
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  return (
    <div className='bg-white p-4 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1'>

      <div className="mb-2">
        <h3 className="text-xl font-bold">{title.split(" - ")[1]}</h3>
      </div>

      <div className="space-y-2">
        <div className="flex items-baseline justify-center">
          {isEnterprise ? (
            <span className="text-3xl font-bold">Custom</span>
          ) : (
            <>
              <span className="text-3xl font-bold">${price}</span>
              <span className="text-gray-500">/month</span>
            </>
          )}
        </div>
        <ul className="space-y-1 text-gray-500 text-sm">
          {Object.entries(features).map(([featureName, featureDescription], index) => (
            <li key={index}>
              <Check className="mr-2 inline-block h-4 w-4" />
              {featureDescription}
              {/* <span className="font-semibold">{featureName.split(" - ")[1]}:</span> {featureDescription} */}
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-4">
        <button
          onClick={handleCheckout}
          className="w-full inline-flex h-10 items-center justify-center rounded-md bg-blue-500 px-8 text-sm font-medium text-white shadow transition-colors hover:bg-blue-600 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blue-600 disabled:pointer-events-none disabled:opacity-50"
        >
          {buttonText}
        </button>
      </div>

    </div>
  );
};

export default PricingPlan;
