import React, { useState, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import VideoStatus from '../components/VideoStatus';

const API_URL = process.env.REACT_APP_API_URL;

const VideoPlayer = ({ url, light }) => {
  const [playing, setPlaying] = useState(false);

  return (
    <div className="player-wrapper relative aspect-w-16 aspect-h-9">
      <ReactPlayer
        className="react-player"
        url={url}
        width="100%"
        height="auto"
        playing={playing}
        controls={true}
        light={light}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        onClickPreview={() => setPlaying(true)}
      />
    </div>
  );
};

export default function VideoPage() {
  const { jobId } = useParams();
  const [videoData, setVideoData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);

  const fetchVideoData = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/video/${jobId}`, { withCredentials: true });
      setVideoData(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching video data:', err);
      setError('Failed to load video data. Please try again later.');
      setLoading(false);
    }
  }, [jobId]);

  const fetchCurrentUser = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/users/me`, { withCredentials: true });
      setCurrentUser(response.data);
    } catch (err) {
      console.error('Error fetching current user:', err);
      setError('Failed to load user data. Please try again later.');
    }
  }, []);

  useEffect(() => {
    fetchVideoData();
    fetchCurrentUser();
  }, [fetchVideoData, fetchCurrentUser]);

  useEffect(() => {
    let timer;
    if (!loading && videoData && !videoData.media_path) {
      timer = setTimeout(() => {
        fetchVideoData();
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [loading, videoData, fetchVideoData]);

  const toggleVisibility = async () => {
    setUpdating(true);
    try {
      const response = await axios.put(
        `${API_URL}/video/${jobId}/visibility`,
        !videoData.public,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      setVideoData(response.data);
    } catch (err) {
      console.error('Error updating video visibility:', err);
      setError('Failed to update video visibility. Please try again later.');
    } finally {
      setUpdating(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!videoData) return <div>No video found</div>;
  if (videoData.status.endsWith("failed")) {
    return (
      <div>
        Video not available.
        <VideoStatus jobId={jobId}/>
      </div>
    );
  }

  if (!videoData.media_path) {
    return (
      <div>
        Waiting for video to be ready...
        <VideoStatus jobId={jobId}/>
      </div>
    );
  }

  const isOwner = currentUser && videoData.by === currentUser.id;

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-4">Video Details</h1>
      <p className="mb-4"><strong>Prompt:</strong> {videoData.prompt}</p>
      <p className="mb-4"><strong>Generated on:</strong> {new Date(videoData.created_at).toLocaleString()}</p>
      <div className="video-container mb-4">
        <VideoPlayer url={`${API_URL}/video/${jobId}/media`} light={`${API_URL}/video/${jobId}/thumbnail`} />
      </div>
      {isOwner && (
        <div className="mb-4">
          <button
            onClick={toggleVisibility}
            disabled={updating}
            className={`px-4 py-2 rounded ${
              videoData.public
                ? 'bg-red-500 hover:bg-red-600 text-white'
                : 'bg-green-500 hover:bg-green-600 text-white'
            } transition-colors duration-200 ease-in-out`}
          >
            {updating ? 'Updating...' : (videoData.public ? 'Unpublish' : 'Publish')}
          </button>
          <span className="ml-2">
            Status: {videoData.public ? 'Published' : 'Private'}
          </span>
          <VideoStatus jobId={jobId}/>
        </div>
      )}
    </div>
  );
}