import React from 'react';
import { Check, X } from 'lucide-react';


const CurrentPlan = ({ currentPlan, onCancelClick, onReactivateClick, isReactivating }) => {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-lg p-6 shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-2xl font-bold">{currentPlan.name}</h3>
        <span className="text-3xl font-bold">${currentPlan.price}<span className="text-lg">/month</span></span>
      </div>
      {currentPlan.nextBillingDate ? <div className="mb-4">
        <p className="text-sm opacity-80">Next billing date: {new Date(currentPlan.nextBillingDate * 1000).toLocaleDateString()}</p>
      </div>: <></>}
      <div className="mb-6">
        <h4 className="font-semibold mb-2">Plan Features:</h4>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-2">
          {Object.entries(currentPlan.features).map(([featureName, featureDescription], index) => (
            <li key={index} className="flex items-center">
              <Check className="mr-2 h-5 w-5 text-green-300" />
              <span><span className="font-semibold">{featureName.split(" - ")[1]}:</span> {featureDescription}</span>
            </li>
          ))}
        </ul>
      </div>
      {currentPlan.price > 0 && (
        currentPlan.cancelAtPeriodEnd ? (
          <div className="bg-red-500 bg-opacity-20 border border-red-300 rounded p-4 mb-4">
            <p className="text-sm font-semibold flex items-center">
              <X className="mr-2 h-5 w-5" />
              Your subscription will be canceled at the end of the current billing period.
            </p>
            <button
              onClick={onReactivateClick}
              disabled={isReactivating}
              className="mt-2 bg-white text-blue-600 hover:bg-blue-100 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
            >
              {isReactivating ? 'Reactivating...' : 'Reactivate Subscription'}
            </button>
          </div>
        ) : (
          <button
            onClick={onCancelClick}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
          >
            Cancel Subscription
          </button>
        )
      )}
    </div>
  );
};

export default CurrentPlan;
