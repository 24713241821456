import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Loader2, AlertCircle, Zap, HelpCircle } from 'lucide-react';

const API_URL = process.env.REACT_APP_API_URL;

function Title() {
  return (
    <header className="text-center my-8">
      <h1 className="text-6xl font-bold text-gray-800">ClpGen</h1>
    </header>
  );
}

function Description() {
  return (
    <p className="text-center mx-auto max-w-xl text-gray-600 mb-8 leading-relaxed text-lg">
      Transform your ideas into captivating videos with AI-powered generation.
    </p>
  );
}

function PromptInput({ prompt, setPrompt }) {
  const handleChange = (event) => {
    setPrompt(event.target.value);
  };

  return (
    <div className="my-6">
      <textarea
        className="w-full px-4 py-3 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition duration-200"
        rows="5"
        value={prompt}
        onChange={handleChange}
        placeholder="Describe the video you want to create here
• Create an educational video explaining what electricity actually is.
• Tell the story behind the creation of Pixar Animation Studios.
• Narrate an adventure story about a little wizard boy."
      />
    </div>
  );
}

function GenerateButton({ prompt }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const disabled = !prompt.trim() || isLoading;

  const handleGenerate = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${API_URL}/video/`,
        { prompt },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          withCredentials: true
        }
      );

      navigate(`/video/${response.data.id}`);
    } catch (error) {
      console.error('There was a problem with the axios request:', error);
      setError('Failed to generate video. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button
        className={`
          w-full px-6 py-3 rounded-lg text-white font-semibold text-lg
          ${disabled
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-blue-600 hover:bg-blue-700 active:bg-blue-800'}
          transition duration-150 ease-in-out
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
        `}
        onClick={handleGenerate}
        disabled={disabled}
      >
        {isLoading ? (
          <div className="flex items-center justify-center">
            <Loader2 className="w-6 h-6 animate-spin mr-2" />
            Generating...
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <Zap className="w-5 h-5 mr-2" />
            Generate Video
          </div>
        )}
      </button>
      {error && (
        <div className="mt-3 text-red-500 flex items-center justify-center">
          <AlertCircle className="w-5 h-5 mr-2" />
          {error}
        </div>
      )}
    </div>
  );
}

function HelpSection() {
  return (
    <div className="mt-8 text-center">
      <a href="/faq" className="text-blue-600 hover:text-blue-800 flex items-center justify-center">
        <HelpCircle className="w-5 h-5 mr-1" />
        Need help? Check our FAQ
      </a>
    </div>
  );
}

function Create() {
  const [prompt, setPrompt] = useState("");

  return (
    <div className="App max-w-2xl mx-auto px-4">
      <Title />
      <Description />
      <PromptInput prompt={prompt} setPrompt={setPrompt} />
      <GenerateButton prompt={prompt} />
      <HelpSection />
    </div>
  );
}

export default Create;